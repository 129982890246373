import React from "react";

const Footer = () => {
  return (
    <div className="w-full flex justify-center my-2">
      @ 2023 All Rights Reserved by AZAI
    </div>
  );
};

export default Footer;
