// {
//   id: "",              id blog
//   date: "",            upload blog date
//   imgTitle: "",        imgae blog, import from ./Pics
//   title: "",           title of blog
//   desc: "",            description of blog
// data: [{
//   header: "",          header section of blog
//   text: "",            text section of blog
// }],
// },
import Post0 from "./Pics/post0.png";
import Post1 from "./Pics/post1.jpg";
import Post2 from "./Pics/post2.jpg";
import Post3 from "./Pics/post3.jpg";
import Post4 from "./Pics/post4.jpg";
import Post5 from "./Pics/post5.jpg";
import Post6 from "./Pics/post6.jpg";

export const postList = [
  {
    id: 6,
    date: "10/3/2024",
    imgTitle: Post6,
    title:
      "นอนอยู่ดีๆสบายๆแล้วก็มียุงสุดน่าหงุดหงิดมาตอมหู บ้างก็กัดจัดจนสุดจะคัน",
    desc: "1) เครื่องดักยุง เครื่องดักยุงเป็นวิธีที่ได้ผลอย่างยิ่งเพราะว่าตัวเครื่องมีการใช้แสงไฟที่จะล่อยุงให้มาติด",
    data: [
      {
        header: "ห้องยุงเยอะหรอ? จัดการด้วย 4 วิธีนี้สิ!!!",
        text: "",
      },
      {
        header: "1) เครื่องดักยุง",
        text: "เครื่องดักยุงเป็นวิธีที่ได้ผลอย่างยิ่งเพราะว่าตัวเครื่องมีการใช้แสงไฟที่จะล่อยุงให้มาติด และเมื่อยุงเข้าไปก็จะมีการปล่อยกระแสไฟฟ้าที่จะสามารถฆ่ายุงได้โดยทันที เปิดทิ้งไว้ได้ 24 ชั่วโมง ถือว่าเป็ยวิธีที่ได้ผลอย่างชัดเจน",
      },
      {
        header: "2) มีช่องเปิดหรือรูจากด้านนอก",
        text: "ก่อนที่จะแก้ปัญหาที่ปลายเหตุ เราลองย้อนไปหาต้นเหตุของปัญหาก่อนดีมั้ย? ก่อนอื่นเริ่มจากการสังเกตุว่าในหอมีช่องเปิดหรือรูที่ยุงสามารถเข้ามาได้มั้ย เมื่อตรวจสอบพบเจอให้หาเทปมาแปะหรือหาอะไรมาอุดโดยทันที",
      },
      {
        header: "3) ไม้ตียุง",
        text: "อาวุธสุดคลาสสิคในการต่อกรกับยุงและแมลงวัน ไม่ว่าใครก็ควรมีติดมือไว้ถ้าอยากให้ที่อยู่อาศัยของคุณปราศจากแมลงและยุง แต่ก็มีข้อยกเว้นถ้าบ้านของคุณมีเด็กเล็กเพราะมันอาจเป็นอันตรายต่อเด็กๆวัยกำลังซนได้",
      },
      {
        header: "4) สมุนไพร",
        text: "กลิ่นฉุนเป็นสิ่งนึงที่ยุงไม่ชอบ ลองใช้สมุนไพรหรือพืชที่มีกลิ่นฉุนดูสิ กระเทียม ตะไคร้ ยูคาลิปตัส หรือ โรสแมรี่ วางไว้ที่มุมห้อง มุมอับ หรือไม่ว่าจะเป็น สถานที่ที่มีน้ำขัง เพื่อป้องกันให้ยุงมาวางไข่ แค่นี้ยุงก็บินหนีและไม่มารบกวนอีกต่อไป",
      },
    ],
  },
  {
    id: 5,
    date: "25/2/2024", // "2021-10-10T00:00:00+07:00
    imgTitle: Post5,
    title: "ข้อควรรู้ในการทำธุรกิจหอพัก อพาร์ทเม้นท์ ในปี2024",
    desc: "1) ทำการตลาดออนไลน์ ออนไลน์เป็นเรื่องที่สำคัญมากๆสำหรับการทำธุรกิจในยุคนี้ ปฏิเสธไม่ได้เลยว่า ออนไลน์เป็นหนทางที่เร็วที่สุดในการหาลูกค้าใหม่ และการสร้างตัวตนออนไลน์จะทำให้เกิด Brand reconiztion",
    data: [
      {
        header: "🌟ข้อควรรู้ การทำธุรกิจ หอพัก อพาร์ทเม้นท์ ในปี2024 🌟",
        text: "",
      },
      {
        header: "1) ทำการตลาดออนไลน์ ",
        text: "ออนไลน์เป็นเรื่องที่สำคัญมากๆสำหรับการทำธุรกิจในยุคนี้ ปฏิเสธไม่ได้เลยว่า ออนไลน์เป็นหนทางที่เร็วที่สุดในการหาลูกค้าใหม่ และการสร้างตัวตนออนไลน์จะทำให้เกิด Brand reconiztion ซึ้งจำเป็นมากๆต่อการจูงใจลูกค้าให้เข้ามาใช้บริการและปิดการขาย",
      },
      {
        header: "2) ดูว่าคู่แข่งในย่านนั้นๆมีความแตกต่างอย่างไร ",
        text: "หอพักของคุณจะต้องมีการออกแบบให้โดดเด่นมากยิ่งขึ้น โดยเฉพาะ การหาสิ่งที่ยังขาดมาเพิ่มหรือทำให้สิ่งที่ดีอยู่แล้วดีขึ้นโดยใช้กลยุทธ์ทางด้านการตลาดซึ่งมีผลสำคัญในการทำธุรกิจหอพัก อพาร์ทเม้นท์ ",
      },
      {
        header: "3) วางแผนการเสียภาษีรายปีให้ดี ",
        text: "เนื่องจากว่าในแต่ละปีนั้นธุรกิจอพาร์ทเม้นท์จะต้องเสียภาษีเงินได้ส่วนบุคคลธรรมดาทั้งแบบสิ้นปีและกลางปี ซึ่งคุณจะต้อง ศึกษาหาความรู้เกี่ยวกับการจ่ายภาษีและมีวิธีการบริหารจัดการไม่ให้ต้องแบกรับภาษีที่มากจนเกินไปแต่คุณจะต้องทำทุกอย่างให้ถูกกฎหมายด้วยเพื่อการคงอยู่ของธุรกิจของคุณให้เติบโตในระยะยาว",
      },
      {
        header: "4) ใช้เทคโนโลยีในการจัดการหอพัก",
        text: "ยุคสมัยนี้มีเทคโนโลยีในการจัดการหอพักแล้ว! อาทิเช่น การทำสัญญาเช่า การจัดการบิล การวิเคราะห์ผลประกอบการ หรือ แม้แต่การสื่อสารกับผู้เช่า ทั้งหมดนี้ทำได้หมดจบในแอปเดียว แน่นอนว่า สะดวก โปร่งใส กำจัดเรื่องวุ่นๆบนกระดาษ ประหยัดเวลาเจ้าของหอไปได้อย่างมาก ",
      },
    ],
  },
  {
    id: 4,
    date: "18/2/2024", // "2021-10-10T00:00:00+07:00
    imgTitle: Post4,
    title: "เรียนเจ้าของห้องเช่าและหอพัก และนายหน้าที่เคารพ",
    desc: 'เราขอแนะนำ "AZAI อาศัย" - แอปพลิเคชั่นที่จะเปลี่ยนการจัดการห้องเช่าและหอพักของคุณให้ง่ายดายและมีประสิทธิภาพยิ่งขึ้น! เพื่อให้คุณได้สัมผัสประสบการณ์การจัดการที่ไม่เคยมีมาก่อน',
    data: [
      {
        header: 'เราขอแนะนำ "AZAI อาศัย"',
        text: "แอปพลิเคชั่นที่จะเปลี่ยนการจัดการห้องเช่าและหอพักของคุณให้ง่ายดายและมีประสิทธิภาพยิ่งขึ้น! เพื่อให้คุณได้สัมผัสประสบการณ์การจัดการที่ไม่เคยมีมาก่อน, เราขอเชิญคุณเข้าร่วมทดลองใช้แอปพลิเคชั่นของเรา ฟรี ผ่านการลงทะเบียนทดลองใช้ใน Google Form นี้",
      },
      {
        header: "",
        text: "📱 รับสิทธิพิเศษ: โดยการลงทะเบียน, คุณจะได้รับสิทธิ์ในการเข้าถึงข้อมูลข่าวสาร, อัปเดตความคืบหน้าการพัฒนา, และโอกาสทดลองใช้ฟีเจอร์ใหม่ๆ ก่อนใครผ่าน Line Official",
      },
      {
        header: "✨ สำหรับใคร?",
        text: `เจ้าของห้องเช่าและหอพักที่ต้องการปรับปรุงการบริหารจัดการ ผู้ที่กำลังมองหาโซลูชันที่ช่วยให้การจัดการเป็นเรื่องง่ายและสะดวก <a href="https://forms.gle/bdg2bTvMXYDXeSAk8" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>🔗 ลงทะเบียนที่นี่:🕒 รีบเลย!</a> จำนวนสิทธิ์ทดลองใช้ฟรีมีจำกัด อย่าพลาดโอกาสที่จะปรับปรุงการจัดการห้องเช่าและหอพักของคุณให้เป็นเรื่องง่ายดายกว่าเดิมเราหวังว่าจะได้ร่วมเดินทางไปกับคุณในการพัฒนาและปรับปรุงการบริหารจัดการห้องเช่าและหอพักให้ดียิ่งขึ้น`,
      },
    ],
  },
  {
    id: 3,
    date: "12/2/2024", // "2021-10-10T00:00:00+07:00
    imgTitle: Post3,
    title: "เข้าพักห้องใหม่ทำยังไงให้ปลอดจาก “สิ่งลี้ลับ”",
    desc: "1) อย่าผิวปาก การผิวปากตอนกลางคืนเป็นความเชื่อส่วนบุคคล ว่าเป็นการเรียกสิ่งลี้ลับบริเวณนั้นจึงไม่ควรทำในเวลากลางคืน",
    data: [
      {
        header:
          "💀ไม่เชื่ออย่าลบหลู่!!!💀 เข้าพักห้องใหม่ทำยังไงให้ปลอดจาก “สิ่งลี้ลับ”👻",
        text: "",
      },
      {
        header: "1) อย่าผิวปาก",
        text: "การผิวปากตอนกลางคืนเป็นความเชื่อส่วนบุคคล ว่าเป็นการเรียกสิ่งลี้ลับบริเวณนั้นจึงไม่ควรทำในเวลากลางคืน นอกไปจากนั้นการผิวปากตอนกลางคืนยังสามารถรบกวนคนห้องข้างๆได้อีกด้วย",
      },
      {
        header: "3) เตียงว่าง ระวังมีใครมานอนนะ!",
        text: "เมื่อได้ห้องพักแบบ เตียงเดี่ยวสองเตียง แต่ว่านอนคนเดียว ควรนำของอย่างอื่นมาวางอีกเตียงหนึ่งให้เต็ม ให้เหมือนมีคนใช้เตียงนั้นด้วย เพราะไม่เช่นนั้น อาจมีสิ่งที่มองไม่เห็นมานอนด้วยก็เป็นได้ ",
      },
      {
        header: "4) อย่านอนหันหัวไปทาง “ทิศแห่งความตาย”",
        text: "เริ่มตั้งแต่การจัดวางของภายในห้อง การจัดหัวเตียงไปทาง “ทิศตะวันตก” นั้น โบราญว่าไว้ว่า เป็นทิศแห่งความตาย ไม่ควรหันหัวไปทางนั้น เพราะอาจฝันร้ายได้ ",
      },
      {
        header: "5) เปิดให้ครบทุกดวงในห้อง",
        text: "สิ่งลี้ลับกลัวแสงสว่าง เมื่อเข้าไปในห้องพักแล้วควรเปิดไฟให้ครบทุกดวง ความเชื่อบอกว่าเป็นการบอกเจ้าที่เจ้าทางหรือผู้ที่เรามองไม่เห็นในห้องว่าจะมีคนใหม่มาพักในห้องนะ",
      },
      {
        header:
          "💀ไม่เชื่ออย่าลบหลู่!!!💀 เข้าพักห้องใหม่ทำยังไงให้ปลอดจาก “สิ่งลี้ลีบ”👻",
        text: "",
      },
    ],
  },
  {
    id: 2,
    date: "4/2/2024", // "2021-10-10T00:00:00+07:00
    imgTitle: Post2,
    title: "5 อันดับสิ่งที่ผู้เช่าโหวตว่ารับไม่ได้มากที่สุด",
    desc: "1) สกปรกไร้การดูแล/ ซ่อมบำรุงเหตุผลสุดฮิตที่ทำให้ผู้เช่าย้ายออกไม่ว่าจะเป็น พื้นทางเดินสกปรก, มีฝุ่นเยอะ, อากาศไม่ถ่ายเท, ลิฟพังบ่อย, ไฟติดๆดับๆ",
    data: [
      {
        header:
          "⚠️⚠️ เจ้าของหอพักต้องอ่าน!!! ⚠️⚠️ สิ่งที่ผู้เช่าโหวตว่ารับไม่ได้มากที่สุดที่เจอมาในห้องเช่า",
        text: "",
      },
      {
        header: "‼️ 1) สกปรกไร้การดูแล/ ซ่อมบำรุง",
        text: "เหตุผลสุดฮิตที่ทำให้ผู้เช่าย้ายออกไม่ว่าจะเป็น พื้นทางเดินสกปรก, มีฝุ่นเยอะ, อากาศไม่ถ่ายเท, ลิฟพังบ่อย, ไฟติดๆดับๆ ทั้งหมดนี้ทำให้บรรยากาศภายในหอพักไม่น่าอยู่อาศัย จนผู้เช่าตัดสินใจไม่ต่อสัญญานั่นเอง",
      },
      {
        header: "‼️ 2) มีคนเสียชีวิตภายในหอพัก",
        text: "แค่ได้ยินหัวข้อก็อยากย้ายออกแล้วใช้มั้ย?! เจ้าของหอพักต้องทำใจไว้เลยว่านี่เป็นเรื่องปกติมากๆที่ผู้เช่าจะอยากย้ายออกหากมีผู้เสียชีวิตภายในหอพักเนื่องจากความหวาดกลัว",
      },
      {
        header: "‼️ 3) มีปัญหารบกวนเสียงดัง",
        text: "เสียงรบกวนเป็นปัญหาที่ทำให้เกิดความรำคาญได้ง่ายที่สุดในหอพักโดยเฉพาะเวลากลางคืนไม่ว่าจะเป็น เสียงคนตะโกน, เสียงคนทะเลาะกัน, เสียงหมาเห่า, เสียงดังปาร์ตี้ หรือ เสียงเพลง เจ้าของหอพักต้องจัดการต้นตอของเสียงให้ได้เร็วที่สุด เพื่อให้บรรยากาศของหอพักเงียบสงบเหมาะต่อการอยู่อาศัย",
      },
      {
        header: "‼️ 4) ความปลอดภัยต่ำ",
        text: "ความปลอดภัยควรเป็นสิ่งที่เจ้าของหอพักให้ความสำคัญเป็นลำดับต้นๆ การที่บุคคลภายนอกสามารถเข้า - ออกหอพักอย่างง่ายดาย, หอพักไม่มีกล้องวงจรปิด, หรือการที่ไม่มีเจ้าหน้าที่ รปภ. ทำให้ผู้เช่า Say Goodbye ได้เช่นกัน",
      },
      {
        header: "‼️ 5) คิดค่าน้ำ/ ค่าไฟ ไม่เป็นธรรม",
        text: "ปัญหาในข้อนี้ถือได้ว่าเป็นปัญหาใหญ่เลยทีเดียว เพราะฉะนั้นภายในสัญญาเช่าจำเป็นที่จะต้องระบุรายละเอียดต่าง ๆ ให้ชัดเจนและมีความเข้าใจที่ตรงกันทั้ง 2 ฝ่าย เพื่อป้องกันปัญหาที่อาจจะตามมาในภายหลัง",
      },
      {
        header: "",
        text: "ในแต่ละวันนอกจากเจ้าของหอพักจะต้องดูแลงานบริหารภายในหอแล้ว เจ้าของหอยังต้องจัดการแก้ไขปัญหาต่างๆที่เกิดขึ้นอีก ทำให้ภาระงานในการดูแลหอพักถือว่าหนักมาก",
      },
      {
        header: "",
        text: "เราขอเสนอ 🏠 AZAI “อาศัย” แอปจัดการหอพักยุคใหม่ ที่จะทำให้คุณลืมการทำงานบริหารหอพักแบบเดิมๆด้วยประสบการณ์ใหม่บน AZAI ที่ยกมาครบทุกฟีเจอร์ จัดเต็มทั้งการทำสัญญาเช่า, การออกบิล, การวิเคราะห์ผลประกอบการ และการสื่อสารกับผู้เช่า ใช้งานง่าย ครบจบที่เดียว ที่ AZAI ",
      },
    ],
  },
  {
    id: 1,
    date: "7/1/2024", // "2021-10-10T00:00:00+07:00
    imgTitle: Post1,
    title: "AZAI “อาศัย” แอปจัดการหอพักยุคใหม่",
    desc: "AZAI “อาศัย” แอปจัดการหอพักยุคใหม่ ที่จะทำให้คุณลืมการทำงานบริหารหอพักแบบเดิมๆด้วยประสบการณ์ใหม่บน AZAI ที่ยกมาครบทุกฟีเจอร์",
    data: [
      {
        header: "AZAI “อาศัย” แอปจัดการหอพักยุคใหม่",
        text: "🏠 AZAI “อาศัย” แอปจัดการหอพักยุคใหม่ ที่จะทำให้คุณลืมการทำงานบริหารหอพักแบบเดิมๆด้วยประสบการณ์ใหม่บน AZAI ที่ยกมาครบทุกฟีเจอร์ จัดเต็มทั้งการทำสัญญาเช่า, การออกบิล, การวิเคราะห์ผลประกอบการ และการสื่อสารกับผู้เช่า ใช้งานง่าย ครบจบที่เดียว ที่ AZAI ",
      },
    ],
  },
  {
    id: 0,
    date: "17/12/2023", // "2021-10-10T00:00:00+07:00
    imgTitle: Post0,
    title: "สวัสดีครับพวกเรา “อาศัย” (AZAI)",
    desc: "สวัสดีครับพวกเรา “อาศัย” (AZAI) เราเป็นหนึ่งในทีมผู้ชนะจากรายการแข่งขัน startup #CUPP2023 ที่จัดโดยหน่วยงานของรัฐบาลฮ่องกง",
    data: [
      {
        header: "สวัสดีครับพวกเรา “อาศัย” (AZAI)",
        text: "เราเป็นหนึ่งในทีมผู้ชนะจากรายการแข่งขัน startup #CUPP2023 ที่จัดโดยหน่วยงานของรัฐบาลฮ่องกง เราภูมิใจนำเสนอแอปพลิเคชั่นจัดการหอพักที่จะมาเปลี่ยนประสบการณ์การเช่าห้องเช่าของคุณอย่างไม่เหมือนเคย",
      },
    ],
  },
];
